site.endeca.result.product = jQuery.extend( true, {}, generic.endeca.result.product, {

    displayResult: function( args ) {
        this.setupReviewData();
        this.setupBrandData();

        // global variables
        this.resultData.globals = {};

        this.resultData.globals.variables = Drupal.settings.common;
        this.resultData.globals.t = site.translations || {};
        this.resultData.priceRange = this.resultData.price_range;

        for (var i = 0; i < this.resultData.skus.length; i++) {
          if (_.isUndefined(this.resultData.skus[i])) {
            continue;
          }
          this.resultData.skus[i].globals = {};
          this.resultData.skus[i].globals.variables = Drupal.settings.common;
          this.resultData.skus[i].globals.t = site.translations || {};
          this.resultData.skus[i].IMAGE_MEDIUM = $.makeArray(this.resultData.skus[i].IMAGE_MEDIUM);
          this.resultData.skus[i].IMAGE_LARGE = $.makeArray(this.resultData.skus[i].IMAGE_LARGE);
        }

        // defaultSku
        this.resultData.defaultSku = _.find(this.resultData.skus, { 'DEFAULT_SKU': 1 }) || this.resultData.skus[0];
        this.resultData.SKU_BASE_ID = this.resultData.defaultSku.SKU_BASE_ID;
        this.resultData.SKU_ID = this.resultData.defaultSku.SKU_ID;

        // medium image from default sku
        this.resultData.IMAGE_MEDIUM = this.resultData.defaultSku.IMAGE_MEDIUM;
        // set large image that QS will load
        this.resultData.IMAGE_LARGE = this.resultData.defaultSku.IMAGE_LARGE;


        // custom processing for single shaded, sku counts
        var isShaded = this.resultData.shaded;
        var isMultiShaded = this.resultData.IS_MULTISHADED;
        var isSku = this.resultData.sku_as_product;
        // i am a sku match
        if (isSku) {
          this.resultData.sku_as_product = true;
          // set a new url for shaded skus so we can target shade on spp load
          if (isShaded) {
            var SkuShadeName = this.resultData.skus[0].SHADENAME;
            var shadeNameNoSpaces = SkuShadeName.toString().replace(/_/g, ' ');
            var shadeNameNoSpaces = SkuShadeName.toString().split(' ').join('_');
            var UrlRoute = '?shade=' + shadeNameNoSpaces;
            // pass standard vars through - for now helping load details on typeahead
            this.resultData.url = this.resultData.url + UrlRoute;
            this.resultData.shaded = true;
            this.resultData.SHADENAME = this.resultData.skus[0].SHADENAME;
            this.resultData.PRODUCT_SIZE = this.resultData.skus[0].PRODUCT_SIZE;
          }
        }
        // i am a product match
        else {
          if (this.resultData.skus.length > 1 && isShaded) {
            this.resultData.shaded_sku_count = this.resultData.skus.length;
          }
          // single shade sets to not shaded
          if (this.resultData.skus.length <= 1 && isShaded) {
            this.resultData.shaded = 0;
          }
          // multishaded
          if (isMultiShaded) {
            for (i = 0; i < this.resultData.skus.length; i++) {
              if (_.isUndefined(this.resultData.skus[i])) {
                continue;
              }
              // mimic the page_data vars we get via the product module
              if (this.resultData.skus[i].HEX_VALUE_STRING.length) {
                var hexes = this.resultData.skus[i].HEX_VALUE_STRING;
                var hexes = hexes.split(',');
                this.resultData.skus[i].MultiShadeHexes = hexes;
                this.resultData.skus[i].MultiShadeCount = hexes.length;
              }
            }
          }
        }

//console.log(this.resultData);

        this._super( args );
    }

});
